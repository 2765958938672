<button class="bg-[#145ca4] z-50 flex-shrink-0 shadow-xl h-16 w-full flex items-center justify-between px-4 text-white" (click)="open = !open">
  <div class="">
    <div class="font-light">{{selectedItem?.title}}</div>
<!--    <div class="text-xs text-gray-200">{{selectedItem?.subtitle}}</div>-->
  </div>
  <div class="w-8 h-8 text-2xl text-white">
    <fa-icon [icon]="hamburgerIcon"></fa-icon>
  </div>
</button>

<div class="flex-grow">
  <ng-content></ng-content>
</div>

<div class="backdrop" [ngClass]="{'open': open}"></div>
<div class="menu" [ngClass]="{'open': open}" (click)="open = false">
  <button *ngFor="let item of navbarItems" (click)="select(item)" class="menu-item">
    <fa-icon class="icon" *ngIf="item.icon" [icon]="item.icon"></fa-icon>
    <div class="text-left">
      <div class="font-light">{{item.title}}</div>
      <div class="text-xs text-gray-700">{{item.subtitle}}</div>
    </div>
  </button>
</div>
