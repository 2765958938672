import { CameraPlugin } from './plugins/camera';
import { InAppBrowser } from './plugins/in-app-browser';
import { MediaCapturePlugin } from './plugins/media-capture';
import { Device } from './plugins/device';
import { hideSplashscreen, Splashscreen } from './plugins/splashscreen';
import { SocialSharing } from './plugins/social-sharing';
import { BarcodeScanner } from './plugins/barcode-scanner';
import { PushNotification } from './plugins/push-notification';
import { LocalNotification } from './plugins/local-notification';
import { NotificationDialog } from './plugins/dialog';
import { StatusBar } from './plugins/statusbar';

export * from './plugins/splashscreen';
export * from './plugins/device';
export * from './plugins/barcode-scanner';
export * from './plugins/camera';
export * from './plugins/dialog';
export * from './plugins/file';
export * from './plugins/in-app-browser';
export * from './plugins/local-notification';
export * from './plugins/media-capture';
export * from './plugins/push-notification';
export * from './plugins/social-sharing';
export * from './plugins/statusbar';

declare global {
    interface Window {
        device: Device | undefined;
        StatusBar: StatusBar;
        plugins: {
            socialsharing: SocialSharing;
        };
        pushNotification: PushNotification;
        cordova: {
            InAppBrowser: InAppBrowser;
            plugins: {
                scanner: BarcodeScanner;
                notification: {
                    local: LocalNotification;
                };
            };
            require: (module: string) => any;
        };
    }

    interface Navigator {
        splashscreen: Splashscreen;
        notification: NotificationDialog;
        camera: CameraPlugin;
        device: {
            capture: MediaCapturePlugin;
        };
    }
}

export function onDeviceReady(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
        document.addEventListener('deviceready', () => resolve(true), false);
    });
}

export interface CordovaAppOptions {
    sentryDsn?: string;
}

export function cordovaAppInitializer(options?: CordovaAppOptions) {
    if (navigator.userAgent.endsWith('(Android App)') || navigator.userAgent.endsWith('(iOS App)')) {
        return () =>
            onDeviceReady().then((isDevice) => {
                if (!isDevice) {
                    return;
                }

                console.log('ready');
                try {
                    hideSplashscreen();
                } catch (e) {
                    console.error(e);
                }
            });
    }

    return () => Promise.resolve();
}

/**
 * Checks if the web app runs in a cordova context
 */
export function isCordova() {
    return typeof window.cordova === 'object';
}
