//import 'cordova-plugin-file'

export interface FileEntry {
    file(callback: (file) => void, error: (err: Error) => void);

    createWriter(callback: (writer: FileWriter) => void);

    toURL(): string;
}

export interface FileWriter {
    onwriteend: any;
    onerror: any;

    write(blob: Blob);
}

declare global {
    interface Window {
        requestFileSystem(
            type: 0 | 1,
            num: 0,
            callback: (fs: FileSystem) => void,
            error: (err: Error) => void,
        );
    }
}

export function getFile(
    path: string,
    options: FileSystemFlags,
): Promise<FileEntry | null> {
    const pathParts = path.split('/');
    if (pathParts.length > 1) {
    }

    return new Promise<FileEntry>((resolve, reject) => {
        window.requestFileSystem(
            1,
            0,
            function (fs) {
                fs.root.getFile(
                    path,
                    options,
                    function (fileEntry) {
                        resolve(fileEntry as any);
                    },
                    reject,
                );
            },
            reject,
        );
    });
}

export async function getFileUrl(path: string): Promise<string | null> {
    const fileEntry = await getFile(path, {create: true, exclusive: false});
    return fileEntry?.toURL();
}

export async function readFile(path: string): Promise<File> {
    const fileEntry = await getFile(path, {create: true, exclusive: false});
    return new Promise<File>((resolve, reject) => {
        fileEntry.file(function (file) {
            resolve(file);
        }, reject);
    });
}

export async function writeFile(path: string, data: Blob): Promise<FileEntry> {
    const fileEntry = await getFile(path, {create: true, exclusive: false});
    return new Promise<FileEntry>((resolve, reject) => {
        fileEntry.createWriter(function (fileWriter: FileWriter) {
            fileWriter.onwriteend = function () {
                resolve(fileEntry);
            };

            fileWriter.onerror = function (e) {
                reject(e);
            };

            fileWriter.write(data);
        });
    });
}
