import {Component, Inject, Input, OnInit} from '@angular/core';
import {NavbarComponent} from '../navbar.component';
import {IconDefinition} from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-navbar-item',
  templateUrl: './navbar-item.component.html',
  styleUrls: ['./navbar-item.component.scss']
})
export class NavbarItemComponent implements OnInit {

  @Input()
  title!: string;

  @Input()
  subtitle?: string;

  @Input()
  icon?: IconDefinition;

  visible: boolean;

  constructor(@Inject(NavbarComponent) parent: NavbarComponent) {
    this.visible = parent.selectedItem === this;
  }

  ngOnInit(): void {
  }

}
