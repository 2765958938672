<app-navbar [selectedItem]="inAppBrowser">
  <app-navbar-item [title]="'InAppBrowser'" [icon]="inAppBrowserIcon" [subtitle]="'Plugin'" #inAppBrowser>
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="openInDialog()">Open in dialog</app-primary>
      <app-primary (click)="openKeycloak()">Open keycloak</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Splashscreen'" [icon]="splashscreenIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="showSplash()">Show for 2 seconds</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'File'" [icon]="fileIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="writeFile()">Write file</app-primary>
      <app-primary (click)="readFile()">Read file</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Statusbar'" [icon]="statusBarIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="hideStatusbar()">Hide</app-primary>
      <app-primary (click)="showStatusbar()">Show</app-primary>
      <app-primary (click)="defaultStatusbar()">Default style</app-primary>
      <app-primary (click)="lightContentStatusbar()">Light content style</app-primary>
      <app-primary (click)="customHexColorStatusbar()">#501c4f hex style</app-primary>
      <app-primary (click)="overlayStatusbar()">Overlay statusbar</app-primary>
      <app-primary (click)="dontOverlayStatusbar()">Dont overlay statusbar</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Social sharing'" [icon]="shareIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="shareText()">Share text</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Camera'" [icon]="cameraIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="openCamera()">Open image camera</app-primary>
      <app-primary (click)="openSelfieCamera()">Open Selfie camera</app-primary>
      <app-primary (click)="openVideoGallery()">Open video gallery</app-primary>
      <app-primary (click)="openGallery()">Open image gallery</app-primary>
      <app-primary (click)="openAllGallery()">Open all gallery</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Media capture'" [icon]="captureIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="openImageCapture()">Open image capture</app-primary>
      <app-primary (click)="openVideoCapture()">Open video capture</app-primary>
      <app-primary (click)="openAudioCapture()">Open audio capture</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Barcode Scanner'" [icon]="barcodeIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="startScanning()">Start scanner</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Geolocation'" [icon]="locationIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="getCurrentGeolocation()">Get current</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Local notification'" [icon]="localNotificationIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="showLocalNotification()">Local</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Push notification'" [icon]="pushNotification">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="registerPushNotification()">Register push</app-primary>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Device'" [icon]="infoIcon">
    <div class="space-y-2 px-3 py-4">
      <pre class="mb-3"><code class="language-typescript">{{deviceCode}}</code></pre>
      <pre><code class="language-json">{{deviceResult | json}}</code></pre>
    </div>
  </app-navbar-item>
  <app-navbar-item [title]="'Sentry'" [icon]="bugIcon">
    <div class="space-y-2 px-3 py-4">
      <app-primary (click)="throwJSError()">Throw js error</app-primary>
      <app-primary (click)="throwNativeError()">Throw native error</app-primary>
    </div>
  </app-navbar-item>
</app-navbar>
