if (navigator.userAgent.endsWith('(Android App)')) {
  loadCordova('http://localhost/cordova.js');
}

function loadCordova(url: string) {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url;
  document.head.appendChild(script);

  document.addEventListener('deviceready', onDeviceReady, false);

  function onDeviceReady() {
    // Cordova is now initialized. Have fun!
    console.log('cordova is ready!');
  }
}
