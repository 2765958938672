import {AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList} from '@angular/core';
import {NavbarItemComponent} from './navbar-item/navbar-item.component';
import { faHamburger } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  hamburgerIcon = faHamburger;
  @Input()
  open = false;

  @ContentChildren(NavbarItemComponent)
  navbarItems?: QueryList<NavbarItemComponent>;

  @Input()
  selectedItem?: NavbarItemComponent;

  constructor() { }

  ngOnInit(): void {
    if(this.selectedItem) {
      this.selectedItem.visible = true;
    }
  }

  select(item: NavbarItemComponent) {
    if(this.selectedItem) {
      this.selectedItem.visible = false;
    }
    this.selectedItem = item;
    this.selectedItem.visible = true;
  }
}
