import {Component} from '@angular/core';
import {getDevice, hideSplashscreen, readFile, showSplashscreen, writeFile} from 'app-cli';
import {
  faBarcode,
  faBrowsers, faBug,
  faCamera, faFile, faHorizontalRule, faInfo, faLocation, faMessage, faMessageExclamation,
  faMobileScreen,
  faShare,
  faVideoCamera,
} from '@fortawesome/pro-light-svg-icons';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  inAppBrowserIcon = faBrowsers;
  splashscreenIcon = faMobileScreen;
  statusBarIcon = faHorizontalRule;
  shareIcon = faShare;
  cameraIcon = faCamera;
  captureIcon = faVideoCamera;
  barcodeIcon = faBarcode;
  locationIcon = faLocation;
  infoIcon = faInfo;
  bugIcon = faBug;
  localNotificationIcon = faMessage;
  pushNotification = faMessageExclamation;
  fileIcon = faFile;

  deviceCode = 'import { getDevice } from \'app-cli\';\n\nconst result = getDevice();';
  deviceResult = getDevice();

  constructor(private sw: SwUpdate) {
    console.log('cordova ', window.cordova);
    console.log('sw enabled ' + this.sw.isEnabled);
    console.log('sw check for update 2');
    this.sw.checkForUpdate().then(res => {
      console.log('has update', res);
      if (res) {
        this.sw.activateUpdate().then(ac => {
          console.log('activate update', ac);
        });
      }
    });
    this.sw.versionUpdates.subscribe(res => {
      console.log('version update', res);
    });
  }

  showSplash() {
    showSplashscreen();

    setTimeout(() => {
      hideSplashscreen();
    }, 2000);
  }

  writeFile() {
    writeFile('test.txt', new Blob(['some file data'], {type: 'text/plain'})).then(() => {
      console.log('write');
    });
  }

  readFile() {
    readFile('test.txt').then(data => {
      console.log('read', data);
      const reader = new FileReader();
      reader.onload = (evt) => {
        console.log(reader.result);
      };
      reader.onloadend = (evt) => {
        console.log(reader.result);
      };
      reader.onerror = function() {
        console.log(reader.error);
      };
      reader.readAsText(data);
      console.log(reader.result)
    });
  }

  showStatusbar() {
    window.StatusBar.show();
  }

  defaultStatusbar() {
    window.StatusBar.styleDefault();
  }

  lightContentStatusbar() {
    window.StatusBar.styleLightContent();
  }

  customHexColorStatusbar() {
    window.StatusBar.backgroundColorByHexString('#501c4f');
  }

  hideStatusbar() {
    window.StatusBar.hide();
  }

  overlayStatusbar() {
    window.StatusBar.overlaysWebView(true);
  }

  dontOverlayStatusbar() {
    window.StatusBar.overlaysWebView(false);
  }

  getCurrentGeolocation() {
    navigator.geolocation.getCurrentPosition(pos => {
      navigator.notification.alert(JSON.stringify({
        timestamp: pos.timestamp,
        coords: {
          accuracy: pos.coords.accuracy,
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
          speed: pos.coords.speed,
          altitudeAccuracy: pos.coords.altitudeAccuracy,
          heading: pos.coords.heading,
        },
      }, null, 2), undefined, 'Current position', 'OK');
    }, err => {
      console.error(err);
      navigator.notification.alert(err.message, undefined, 'Error on current position', 'OK');
    }, {timeout: 5000});
  }

  throwJSError() {
    throw new Error('js app test error');
  }

  throwNativeError() {
    const Sentry = window.cordova.require('sentry-cordova.Sentry');
    console.log(Sentry);
    Sentry.nativeCrash();
  }

  shareText() {
    const options = {
      message: 'Share text title', // not supported on some apps (Facebook, Instagram)
      subject: 'Share subject', // fi. for email
      files: ['', ''], // an array of filenames either locally or remotely
      url: 'https://www.example.com/share-test/#bar?a=b',
      // chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
      // appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
      // iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    };

    window.plugins.socialsharing.shareWithOptions(options, result => console.log(result), err => console.error(err));
  }

  startScanning() {
    const settings = window.cordova.plugins.scanner.getDefaultSettings();
    window.cordova.plugins.scanner.startScanning(pos => {
      navigator.notification.alert(pos, undefined, 'Scan result', 'OK');
    }, err => {
      console.error(err);
      navigator.notification.alert(err.message, undefined, 'Error on scanning', 'OK');
    }, settings);
  }

  openCamera() {
    navigator.camera.getPicture(img => {
      console.log(img);
    }, err => {
      console.error(err);
    }, {});
  }

  openSelfieCamera() {
    navigator.camera.getPicture(img => {
      console.log(img);
    }, err => {
      console.error(err);
    }, {
      cameraDirection: 1,
    });
  }

  openVideoGallery() {
    navigator.camera.getPicture(img => {
      console.log(img);
    }, err => {
      console.error(err);
    }, {
      mediaType: 1,
      sourceType: 0,
    });
  }

  openGallery() {
    navigator.camera.getPicture(img => {
      console.log(img);
    }, err => {
      console.error(err);
    }, {
      sourceType: 0,
    });
  }

  openAllGallery() {
    navigator.camera.getPicture(img => {
      console.log(img);
    }, err => {
      console.error(err);
    }, {
      mediaType: 2,
      sourceType: 0,
    });
  }

  openAudioCapture() {
    navigator.device.capture.captureAudio(
      console.log, console.error,
    );
  }

  openVideoCapture() {
    navigator.device.capture.captureVideo(
      console.log, console.error,
    );
  }

  openImageCapture() {
    navigator.device.capture.captureImage(
      console.log, console.error,
    );
  }

  showLocalNotification() {
    window.cordova.plugins.notification.local.schedule({
      title: 'My first notification',
      text: 'Thats pretty easy...',
      foreground: true,
    });
  }

  registerPushNotification() {
    window.pushNotification.registration(
      (token) => {
        console.log(token);
      },
      (error) => {
        console.error(error);
      },
    );
  }

  openKeycloak() {
    //const url = 'https://appleid.apple.com/auth/authorize?response_mode=form_post&scope=openid%20name%20email&state=THDqbHCvqS3VVZVzDSuCtw86DlwYyfiCW6oFTJ0gb3c.MHlin7xFH-8.ethos&response_type=code&client_id=com.triarc-labs.ideesport.ethos&redirect_uri=https%3A%2F%2Fauth.devcloud.triarc-labs.com%2Fauth%2Frealms%2Fethos%2Fbroker%2Fapple%2Fendpoint&nonce=P7Q6cHtNGs29VAEETwKCRg';
    const redirect = window.location.host;
    const url = 'https://auth.devcloud.triarc-labs.com/auth/realms/ethos/protocol/openid-connect/auth?client_id=ethos&state=123&redirect_uri=https%3A%2F%2Fappcli.triarc-labs.com&response_mode=fragment&response_type=code&scope=openid';
    const ref = window.cordova.InAppBrowser.open(url, '_blank', 'location=no,beforeload=yes');
    ref.addEventListener('loadstart', () => {
      console.log('loadstart', arguments);
    });
    ref.addEventListener('loadstop', () => {
      console.log('loadstop', arguments);
    });
    ref.addEventListener('loaderror', (err) => {
      console.log('loaderror', err);
    });
    ref.addEventListener('beforeload', (params, callback) => {
      console.log('beforeload', params);
      if (params.url.startsWith('http://localhost')) {

        // Load this URL in the inAppBrowser.
      }
      callback(params.url);
    });
    ref.addEventListener('message', () => {
      console.log('message', arguments);
    });
  }

  openInDialog() {
    const ref = window.cordova.InAppBrowser.open('https://example.com', '_blank', 'location=yes,beforeload=yes');
    ref.addEventListener('loadstart', () => {
      console.log('loadstart', arguments);
    });
    ref.addEventListener('loadstop', () => {
      console.log('loadstop', arguments);
    });
    ref.addEventListener('loaderror', (err) => {
      console.log('loaderror', err);
    });
    ref.addEventListener('beforeload', (params, callback) => {
      console.log('beforeload', params);
      if (params.url.startsWith('https://example.com/')) {

        // Load this URL in the inAppBrowser.
        callback(params.url);
      }
    });
    ref.addEventListener('message', () => {
      console.log('message', arguments);
    });
  }
}
