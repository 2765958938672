import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrimaryComponent} from './primary.component';

@NgModule({
  declarations: [
    PrimaryComponent,
  ],
  exports: [PrimaryComponent],
  imports: [
    CommonModule,
  ],
})
export class PrimaryModule {
}
