export function getDevice(): Device | null {
  if (window.device) {
    return window.device;
  }

  return null;
}

export interface Device {
  available: boolean;
  cordova: string;
  isVirtual: boolean;
  isiOSAppOnMac: boolean;
  manufacturer: string;
  model: string;
  platform: 'iOS' | 'Android';
  serial: string;
  uuid: string;
  version: string;
}
