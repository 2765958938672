export interface Splashscreen {
  show: () => void;
  hide: () => void;
}

export async function showSplashscreen() {
  navigator.splashscreen.show();
}

export async function hideSplashscreen() {
  navigator.splashscreen.hide();
}
